import React from "react";
import Image from "react-bootstrap/Image";
import logo from "../assets/logo.png";
import { Instagram, Mail, Telegram } from "@mui/icons-material";

// import faInstagram from "@fortawesome/free-brands-svg-icons/faInstagram";
const TikTokIcon = ({ color = "#000000" }) => {
  return (
    <svg fill={color} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="25px" height="25px">
      <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
    </svg>
  );
};

const Footer = () => (
  <footer className="footer font-small blue mt-5" style={{ background: "#faf0e6" }}>
    <hr />
    <div className="container-fluid text-center text-md-left">
      <div className="row">
        <div className="col-md-6 mt-md-0 mt-2">
          <h5 className="text-uppercase">
            <Image width="75" height="75" src={logo} alt="Bijohui logo" />
          </h5>
          <p>Your local crafter.</p>
        </div>
        <hr className="clearfix w-100 d-md-none pb-0" />
        <div className="col-md-4 mb-md-0 mb-1">
          <ul className="pt-4 list-unstyled">
            <li className="d-inline mx-4">
              <a href="https://www.instagram.com/bijouhui" target="_blank" rel="noreferrer">
                <Instagram className="text-dark" />
              </a>
            </li>
            <li className="d-inline mx-4">
              <a href="https://t.me/bijouhui">
                <Telegram className="text-dark" />
              </a>
            </li>
            <li className="d-inline mx-4">
              <a href="https://www.tiktok.com/@huiyce3" target="_blank" rel="noreferrer">
                <TikTokIcon />
              </a>
            </li>
            <li className="d-inline mx-4">
              <a href="mailto:bijouhui.co@gmail.com">
                <Mail className="text-dark" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div className="footer-copyright text-center py-1 " style={{ fontSize: "15px" }}>
      © 2024 Bjouhui. All rights reserved.
    </div>
  </footer>
);

export default Footer;

import Container from "react-bootstrap/Container";
import TodayPick from "../components/todayPick";
import Navbar from "../components/navbar";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Footer from "../components/footer";
import { useEffect } from "react";
import Hero from "../components/hero";
import sets from "../assets/category/sets.svg";
import bracelets from "../assets/category/bracelets.svg";
import rings from "../assets/category/rings.jpg";

function Home() {
  useEffect(() => {
    document.title = "Bijouhui - Home";
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Container fluid style={{ padding: 0, width: "100%" }}>
        <Navbar />
        <Hero />
        <Toolbar className="mt-1" />
        {/* <section className="text-center pt-4">
          <h3>Our long-awaited launch drop is here 😊</h3>
          <Image src={shopLaunch} width="350px" height="350px" alt="brand" fluid />
        </section> */}
        <Box component="main" sx={{ width: "85%", margin: "auto", marginTop: "50px", textAlign: "center" }}>
          <Toolbar className="mt-1" />
          <h2>Modern, meaningful jewelery</h2>
          <p style={{ fontWeight: "light", fontFamily: "Karla, sans-serif" }}>Sharing the beauty of handmade joy.</p>
          <div className="mx-auto text-center">
            <Row>
              <Col className="col-6 col-md-4 col-lg-4">
                <Image src={bracelets} alt="Bijouhui logo" width="300" height="260" className="rounded-circle p-2" fluid />
                <h6>Bracelets</h6>
              </Col>
              <Col className="col-6 col-md-4 col-lg-4">
                <Image src={rings} alt="Bijouhui logo" width="300" height="260" className="rounded-circle p-2" fluid />
                <h6>Rings</h6>
              </Col>
              <Col className="col-6 col-md-4 col-lg-4">
                <Image src={sets} alt="Bijouhui logo" width="300" height="260" className="rounded-circle p-2" fluid />
                <h6>Sets</h6>
              </Col>
            </Row>
          </div>
          <Toolbar className="mt-1" />
          <TodayPick />
        </Box>
      </Container>
      <Footer />
    </>
  );
}
export default Home;

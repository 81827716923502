import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import Image from "react-bootstrap/Image";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import logo from "../assets/logo.png";
import { NavLink } from "react-router-dom";

const drawerWidth = 240;
const navItems = ["Home", "About", "Shop", "FAQ"];

function Navbar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center", fontFamily: "Libre Baskerville, serif" }}>
      <Typography variant="h6" sx={{ my: 2, fontFamily: "Libre Baskerville, serif" }}>
        <Image width="50" height="50" src={logo} alt="Bijohui logo" />
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) =>
          item !== "Home" ? (
            <NavLink
              to={"/" + item}
              className="text-decoration-none text-black me-4"
              style={({ isActive, isPending }) => {
                return {
                  fontWeight: isActive ? "bold" : "",
                  textDecoration: isActive ? "underline" : "none",
                  color: isPending ? "black" : "black",
                  fontFamily: "Libre Baskerville, serif",
                };
              }}
            >
              {/* <ListItemButton sx={{ textAlign: "center" }}> */}
              <ListItemText primary={item} style={{ fontFamily: "Libre Baskerville, serif" }} />
              {/* </ListItemButton> */}
            </NavLink>
          ) : (
            // </ListItem>
            <NavLink
              to="/"
              className="text-decoration-none text-black me-4"
              style={({ isActive, isPending }) => {
                return {
                  fontWeight: isActive ? "bold" : "",
                  textDecoration: isActive ? "underline" : "none",
                  color: isPending ? "black" : "black",
                  fontFamily: "Libre Baskerville, serif",
                };
              }}
            >
              <ListItemText primary="Home" />
            </NavLink>
          )
        )}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar component="nav" style={{ background: "white", color: "black", backgroundColor: "#faf0e6" }}>
        <Toolbar>
          <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ mr: 2, display: { sm: "none" } }}>
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}>
            <Image width="50" height="50" src={logo} alt="Bijohui logo" />
          </Typography>
          <Box sx={{ display: { xs: "none", sm: "block" }, color: "black" }}>
            {navItems.map((item) =>
              item !== "Home" && item !== "Shop" ? (
                <NavLink
                  to={"/" + item}
                  className="text-decoration-none text-black me-4"
                  style={({ isActive, isPending }) => {
                    return {
                      fontWeight: isActive ? "bold" : "",
                      textDecoration: isActive ? "underline" : "none",
                      color: isPending ? "black" : "black",
                      fontFamily: "Libre Baskerville, serif",
                    };
                  }}
                >
                  {item}
                </NavLink>
              ) : item === "Shop" ? (
                <NavLink
                  to={"/" + item}
                  className="text-decoration-none text-black me-4"
                  style={({ isActive, isPending }) => {
                    return {
                      fontWeight: isActive ? "bold" : "",
                      textDecoration: isActive ? "underline" : "none",
                      color: isPending ? "black" : "black",
                      fontFamily: "Libre Baskerville, serif",
                    };
                  }}
                >
                  {item}
                </NavLink>
              ) : (
                // <Dropdown>
                //   <Dropdown.Toggle variant="" className="bg-transparent border-0 p-0">
                //     Shop
                //   </Dropdown.Toggle>
                //   <Dropdown.Menu>
                //     <Dropdown.Item>
                //       <NavLink
                //         to="/shop/bracelets"
                //         className="text-decoration-none text-black me-4"
                //         style={({ isActive, isPending }) => {
                //           return {
                //             fontWeight: isActive ? "bold" : "",
                //             textDecoration: isActive ? "underline" : "none",
                //             color: isPending ? "black" : "black",
                //           };
                //         }}
                //       >
                //         Bracelets
                //       </NavLink>
                //     </Dropdown.Item>
                //     <Dropdown.Item>
                //       <NavLink
                //         to="/shop/rings"
                //         className="text-decoration-none text-black me-4"
                //         style={({ isActive, isPending }) => {
                //           return {
                //             fontWeight: isActive ? "bold" : "",
                //             textDecoration: isActive ? "underline" : "none",
                //             color: isPending ? "black" : "black",
                //           };
                //         }}
                //       >
                //         Rings
                //       </NavLink>
                //     </Dropdown.Item>
                //     <Dropdown.Item>
                //       <NavLink
                //         to="/shop/charms"
                //         className="text-decoration-none text-black me-4"
                //         style={({ isActive, isPending }) => {
                //           return {
                //             fontWeight: isActive ? "bold" : "",
                //             textDecoration: isActive ? "underline" : "none",
                //             color: isPending ? "black" : "black",
                //           };
                //         }}
                //       >
                //         Charms
                //       </NavLink>
                //     </Dropdown.Item>
                //     {/* <Dropdown.Item href="">Rings</Dropdown.Item>
                //       <Dropdown.Item href="">Charms</Dropdown.Item> */}
                //   </Dropdown.Menu>
                // </Dropdown>
                <NavLink
                  to="/"
                  className="text-decoration-none text-black me-4"
                  style={({ isActive, isPending }) => {
                    return {
                      fontWeight: isActive ? "bold" : "",
                      textDecoration: isActive ? "underline" : "none",
                      color: isPending ? "black" : "black",
                      fontFamily: "Libre Baskerville, serif",
                    };
                  }}
                >
                  Home
                </NavLink>
              )
            )}
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            fontFamily: "Libre Baskerville, serif",
            "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}

Navbar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Navbar;

export const todayPickData = [
  { id: "daisy", category: "Bracelets", title: "Daisy", desc: { size: "approx 16cm + 2cm, 19cm + 2cm", materials: "glass beads" }, price: 8 },
  { id: "bubbles", category: "Sets", title: "Bubbles", desc: { size: "Bracelet - approx 16cm (extendable by a cm), Ring - free size", materials: "glass beads" }, price: 10 },
  { id: "seaside", category: "Bracelets", title: "Seaside", desc: { size: "approx 16cm + 3cm, 17cm + 3cm", materials: "natural freshwater beads, glass beads" }, price: 6},
  { id: "aquila", category: "Rings", title: "Aquila", desc: { size: "Free Size", materials: "glass beads" }, price: 4 },
];

export const faqData = [{ id: 1, question: "How do I care for & maintain my beaded jewelery?", desc: ["Avoid exposure to moisture: Remove jewelry before engaging in water activities.", "Keep away from chemicals: Avoid contact with perfumes, lotions etc, as they can damage the beads & metal components (if any) over time.", "Store properly: Store jewelry in a dry, cool place away from direct sunlight to prevent fading & tarnishing. Consider using a jewelry box / pouch to protect it from scratches & dust.", "Handle with care: Avoid pulling / tugging on the beads, which are strung on a wire / thread."] }];

export const shopFilters = ["All", "Bracelets", "Rings", "Sets"];

import { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import TodayPick from "../components/todayPick";
import Navbar from "../components/navbar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Footer from "../components/footer";

function Contact() {
  useEffect(() => {
    document.title = "Bijouhui - Contact";
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Container fluid style={{ padding: "20px" }}>
        <Navbar />
        <Box component="main" sx={{ p: 3 }}>
          <Toolbar />
          <div className="shop text-center mx-auto" style={{ width: "80%" }}>
            <h2 className="font-weight-bold pb-4">Our Contact</h2>
            <hr />
            <Row className="mx-auto pb-5">
              <Typography style={{ fontWeight: "light", fontFamily: "Karla, sans-serif" }}>Have an item that caught your eye? Feel free to reach out to us via Instagram DM or Telegram for any inquiries or purchases.</Typography>
            </Row>
            <Row className="mx-auto pb-5"></Row>
          </div>
        </Box>
      </Container>
      <Footer />
    </>
  );
}
export default Contact;

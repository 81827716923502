import { Image, Col, Row, Badge } from "react-bootstrap";
import brand from "../assets/logo.jpg";
import { Instagram, Mail, Telegram } from "@mui/icons-material";

// import faInstagram from "@fortawesome/free-brands-svg-icons/faInstagram";
const TikTokIcon = ({ color = "#000000" }) => {
  return (
    <svg className="socials" fill={color} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="25px" height="25px">
      <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
    </svg>
  );
};

function Hero() {
  return (
    <section style={{ marginTop: "120px" }}>
      <Row className="animatedHero">
        <Col className="text-center mx-auto pe-0">
          <h1 className="text-center">Welcome to Bijouhui</h1>
          <p style={{ fontWeight: "light", fontFamily: "Karla, sans-serif" }}>Bracelets, Rings & Keychains</p>
          <Image src={brand} style={{ width: "250px", height: "250px", border: "1px solid black", borderRadius: "50%" }} />
          <div className="text-center mx-auto mt-4">
            <Badge bg="dark" className="shopAll p-2 mx-2 me-3">
              <a href="/shop" className="text-decoration-none text-white">
                Shop All
              </a>
            </Badge>
            <a href="https://www.instagram.com/bijouhui" className="text-decoration-none text-dark" target="_blank" rel="noreferrer">
              <Instagram className="socials mx-2" />
            </a>
            <a href="https://t.me/bijouhui" className="text-decoration-none text-dark" target="_blank" rel="noreferrer">
              <Telegram className="socials mx-2" />
            </a>
            <a href="https://www.tiktok.com/@huiyce3" className="text-decoration-none text-dark mx-2" target="_blank" rel="noreferrer">
              <TikTokIcon />
            </a>
            <a href="mailto:bijouhui.co@gmail.com" className="text-decoration-none text-dark" target="_blank" rel="noreferrer">
              <Mail className="socials mx-2" />
            </a>
          </div>
        </Col>
      </Row>
    </section>
  );
}

export default Hero;

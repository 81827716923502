import Container from "react-bootstrap/Container";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Navbar from "../components/navbar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Footer from "../components/footer";
import { useEffect } from "react";
import { faqData } from "../utils/constants";

function Faq() {
  useEffect(() => {
    document.title = "Bijouhui - FAQ & Terms";
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Container fluid style={{ padding: "20px" }}>
        <Navbar />
        <Box component="main" sx={{ p: 3 }}>
          <Toolbar />
          <div className="text-center w-75 mx-auto">
            <section className="faq pb-2">
              <h3>Terms & FAQ</h3>
              <section className="tandc pb-5">
                {/* <Image width="350" height="300" src={faq} alt="faq" fluid /> */}

                <Typography className="text-start" style={{ fontFamily: "Karla, sans-serif" }}>
                  {/* <p className="text-center" style={{ fontStyle: "italic" }}>
                    Bijouhui appreciates that customers review and comply with our T&Cs when making a purchase.
                  </p> */}
                  <h6 className="fw-bold mt-5">
                    Dealing
                    <hr />
                  </h6>
                  ✩ First come first serve basis
                  <br />✩ No meetups
                  <br />
                  ✉️ SingPost mailing
                  <br />
                  ✩ Normal: included in pricing of product, takes ~1-3 working days
                  <br />
                  ✩ Tracked: additional $2.50
                  <br />
                  <br />
                  ✩ Photo proof will be taken before mailing
                  <br />✩ Please note that once the product has been shipped, we will not be held liable if an incorrect address results in lost mail
                  <h6 className="fw-bold mt-5">
                    Remakes <hr />
                  </h6>
                  ✩ Remakes are available if stated (length adjustments) +$0.50
                  <h6 className="fw-bold mt-5">
                    💸 Payment methods <hr />
                  </h6>
                  ✩ Paynow, Paylah, Bank Transfer
                  <br />✩ Strictly ❌ refunds / exchanges, unless under special circumstances (eg. Product delivered was damaged / Wrong specification of product…)
                  <br />✩ Reservation of products is up to 48h. Payment must be made within 24h after notice is sent
                  <br />✩ Order will be mailed out by the end of the week (unless stated otherwise)
                  <h6 className="fw-bold mt-5">
                    Notes <hr />
                  </h6>
                  ✩ Products are handcrafted, hence, please understand that they’re not 100% perfect
                  <br />
                  ✩ Beaded jewelry can be delicate, handle it with care to avoid accidental breakage / damage
                  <br />✩ Grading standards (A,B) reflect the quality of items
                </Typography>
              </section>
              {/* <Typography className="pb-4">Please see below for the frequently asked questions.</Typography> */}
              {faqData.map((faq) => {
                return (
                  <Accordion className="text-start mx-auto border border-light box-shadow">
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header" className="fw-bold border-1">
                      {faq.question}
                    </AccordionSummary>
                    <AccordionDetails className="text-start" style={{ fontWeight: "light", fontFamily: "Karla, sans-serif" }}>
                      <ul>
                        {faq.desc.map((desc) => {
                          return (
                            <li className="pb-2">
                              <b>{desc.split(":").slice(0, 1)}:</b>
                              {desc.split(":").slice(1)}
                            </li>
                          );
                        })}
                      </ul>
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </section>
          </div>
        </Box>
      </Container>
      <Footer />
    </>
  );
}
export default Faq;

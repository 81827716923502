import Container from "react-bootstrap/Container";
import Navbar from "../components/navbar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Footer from "../components/footer";
import { useEffect } from "react";
import { Row, Col, Image } from "react-bootstrap";
// import MasonryImageList from "../components/imageList";
import shopLaunch from "../assets/shopLaunch.jpg";

function About() {
  useEffect(() => {
    document.title = "Bijouhui - About";
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Container fluid style={{ padding: "20px" }}>
        <Navbar />
        <Box component="main" sx={{ p: 3 }}>
          <Toolbar />
          <div className="text-center mx-auto" style={{ width: "80%" }}>
            <h2 className="font-weight-bold pb-4">Our Story</h2>
            <hr />
            <Row className="mx-auto">
              <Col className="col-12 col-md-5 col-lg-4">
                {/* <MasonryImageList /> */}
                <Image src={shopLaunch} width="400" height="350" className="p-2" alt="Background" fluid />
              </Col>
              <Col className="col-12 col-md-7 col-lg-8">
                <Typography className="text-start" style={{ fontFamily: "Karla, sans-serif" }}>
                  Bijouhui was formed in 2024 by Joyce, a creative and passionate beads crafter. What started as a hobby soon transformed into a vision to inspire individuals to explore the art of beadwork.
                  <br /> <br />
                  <i>Bijou</i> is a French word that stands for “piece of jewelry”. Each of our jewelry pieces is meticulously handcrafted with care. Whether you’re looking for a thoughtful gift or a treat for yourself, our selection of beaded jewelry can offer something for you.
                  <br /> <br />
                  Our journey is fueled by a deep appreciation for the diverse stories each bead carries. From vibrant hues to intricate patterns, our carefully selected beads are suitable for everyone.
                  <br /> <br />
                  Just very recently, Bijouhui had its shop launch on 15<sup>th</sup> March, and we can't wait to see what this journey brings! Thank you for being a part of the Bijouhui story.
                </Typography>
              </Col>
            </Row>
          </div>
        </Box>
      </Container>
      <Footer />
    </>
  );
}
export default About;

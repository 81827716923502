import { useEffect, useState } from "react";
import Loader from "../components/standard/loader";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Navbar from "../components/navbar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Footer from "../components/footer";
import { FiFilter } from "react-icons/fi";
import { shopFilters, todayPickData } from "../utils/constants";
import seaside from "../assets/bracelets/seaside.jpg";
import daisy from "../assets/bracelets/daisy.jpg";
import bubbles from "../assets/sets/bubbles.jpg";
import aquila from "../assets/rings/aquila.jpg";

function Shop() {
  const [loading, setLoading] = useState(false);
  const [shopItems, setShopItems] = useState([]);
  const [shopHeader, setShopHeader] = useState("All");
  const [noShopItems, setNoShopItems] = useState(todayPickData.length);

  const sortByshopItem = (shopItem) => {
    setLoading(true);
    let shopItems = [];
    let shopHeader = shopItem;
    if (shopItem === "All") {
      setNoShopItems(todayPickData.length);
    } else {
      for (let i = 0; i < todayPickData.length; i++) {
        if (todayPickData[i].category === shopItem) {
          shopItems.push(todayPickData[i]);
        }
      }
      setNoShopItems(shopItems.length);
    }
    setShopItems(shopItems);
    setShopHeader(shopHeader);
    setLoading(false);
  };

  useEffect(() => {
    document.title = "Bijouhui - Shop";
    window.scrollTo(0, 0);
    setLoading(true);
    let shopItems = [];
    for (let i = 0; i < todayPickData.length; i++) {
      shopItems.push(todayPickData[i]);
    }
    setShopItems(shopItems);
    setLoading(false);
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <Container fluid style={{ padding: "20px" }}>
            <Navbar />
            <Box component="main" sx={{ p: 3 }}>
              <Toolbar />
              <div className="shop text-center mx-auto" style={{ width: "80%" }}>
                <h2 className="font-weight-bold pb-4">Our Shop</h2>
                <hr />
                <Row className="mx-auto pb-5">
                  <Typography style={{ fontWeight: "light", fontFamily: "Karla, sans-serif", fontStyle: "italic" }}>
                    Bijouhui appreciates that customers review and comply with our <a href="/Faq">T&Cs</a> when making a purchase.
                    <br />
                    Have an item that caught your eye? Feel free to reach out to us via Instagram DM or Telegram for any inquiries or purchases.
                  </Typography>
                </Row>
                <Row className="mx-auto pb-5">
                  <Dropdown className="text-start d-inline">
                    <OverlayTrigger placement="top" overlay={<Tooltip>Filter by Items</Tooltip>}>
                      <Dropdown.Toggle className="bg-transparent border-none d-inline" variant="none" size="sm">
                        <FiFilter className="text-dark" />
                        <span className="ps-2 text-start">(Filtered by {shopHeader})</span>
                      </Dropdown.Toggle>
                    </OverlayTrigger>
                    <Dropdown.Menu>
                      <Dropdown.Header>Filter By</Dropdown.Header>
                      {shopFilters.map((shopItem) => (
                        <Dropdown.Item key={shopItem} onClick={() => sortByshopItem(shopItem)}>
                          {shopItem}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                  <br />
                  {shopHeader === "All" ? (
                    <>
                      <Col className="col-12 col-sm-6 col-md-4 col-lg-3 p-3">
                        <Image width="200" height="200" src={seaside} alt="seaside" style={{ borderRadius: "5%" }} fluid />
                        <br />
                        <div style={{ width: "200px", margin: "auto" }}>
                          <small className="font-sm" style={{ fontWeight: "light", fontFamily: "Karla, sans-serif" }}>
                            <b>Pricing: </b>${todayPickData[0].price}
                          </small>
                          <br />
                          <small className="font-sm" style={{ fontWeight: "light", fontFamily: "Karla, sans-serif" }}>
                            <b>Sizing: </b>
                            {todayPickData[0].desc.size}
                          </small>
                          <br />
                          <small style={{ fontWeight: "light", fontFamily: "Karla, sans-serif" }}>
                            <b>Material:</b> {todayPickData[0].desc.materials}
                          </small>
                        </div>
                      </Col>
                      <Col className="col-12 col-sm-6 col-md-4 col-lg-3 p-3">
                        <Image width="200" height="200" src={daisy} alt="daisy" style={{ borderRadius: "5%" }} fluid />
                        <br />
                        <div style={{ width: "200px", margin: "auto" }}>
                          <small className="font-sm" style={{ fontWeight: "light", fontFamily: "Karla, sans-serif" }}>
                            <b>Pricing: </b>${todayPickData[1].price}
                          </small>
                          <br />
                          <small className="font-sm" style={{ fontWeight: "light", fontFamily: "Karla, sans-serif" }}>
                            <b>Sizing: </b>
                            {todayPickData[1].desc.size}
                          </small>
                          <br />
                          <small style={{ fontWeight: "light", fontFamily: "Karla, sans-serif" }}>
                            <b>Material:</b> {todayPickData[1].desc.materials}
                          </small>
                        </div>
                      </Col>
                      <Col className="col-12 col-sm-6 col-md-4 col-lg-3 p-3">
                        <Image width="200" height="200" src={bubbles} alt="bubbles" style={{ borderRadius: "5%" }} fluid />
                        <div style={{ width: "200px", margin: "auto" }}>
                          <small className="font-sm" style={{ fontWeight: "light", fontFamily: "Karla, sans-serif" }}>
                            <b>Pricing: </b>${todayPickData[2].price}
                          </small>
                          <br />
                          <small className="font-sm" style={{ fontWeight: "light", fontFamily: "Karla, sans-serif" }}>
                            <b>Sizing: </b>
                            {todayPickData[2].desc.size}
                          </small>
                          <br />
                          <small style={{ fontWeight: "light", fontFamily: "Karla, sans-serif" }}>
                            <b>Material:</b> {todayPickData[2].desc.materials}
                          </small>
                        </div>
                      </Col>
                      <Col className="col-12 col-sm-6 col-md-4 col-lg-3 p-3">
                        <Image width="200" height="200" src={aquila} alt="aquila" style={{ borderRadius: "5%" }} fluid />
                        <br />
                        <div style={{ width: "200px", margin: "auto" }}>
                          <small className="font-sm" style={{ fontWeight: "light", fontFamily: "Karla, sans-serif" }}>
                            <b>Pricing: </b>${todayPickData[3].price}
                          </small>
                          <br />
                          <small className="font-sm" style={{ fontWeight: "light", fontFamily: "Karla, sans-serif" }}>
                            <b>Sizing: </b>
                            {todayPickData[3].desc.size}
                          </small>
                          <br />
                          <small style={{ fontWeight: "light", fontFamily: "Karla, sans-serif" }}>
                            <b>Material:</b> {todayPickData[3].desc.materials}
                          </small>
                        </div>
                      </Col>
                    </>
                  ) : (
                    shopItems.map((shopItem, id) => (
                      <Col className="col-12 col-sm-6 col-md-4 col-lg-3 p-3">
                        <Image width="200" height="200" src={require(`../assets/${shopItem.category.toLowerCase()}/${shopItem.id}.jpg`)} alt={shopItem.id} style={{ borderRadius: "5%" }} fluid />
                        <br />
                        <div style={{ width: "200px", margin: "auto" }}>
                          <small className="font-sm" style={{ fontWeight: "light", fontFamily: "Karla, sans-serif" }}>
                            <b>Pricing: </b>${shopItem.price}
                          </small>
                          <br />
                          <small className="font-sm" style={{ fontWeight: "light", fontFamily: "Karla, sans-serif" }}>
                            <b>Sizing: </b>
                            {shopItem.desc.size}
                          </small>
                          <br />
                          <small style={{ fontWeight: "light", fontFamily: "Karla, sans-serif" }}>
                            <b>Material: </b>
                            {shopItem.desc.materials}
                          </small>
                        </div>
                      </Col>
                    ))
                  )}
                  <span className="text-center">And more coming soon...</span>
                  <br /> <br />
                  <span className="text-end">{noShopItems} item(s) found.</span>
                </Row>
              </div>
            </Box>
          </Container>
          <Footer />
        </>
      )}
    </>
  );
}
export default Shop;
